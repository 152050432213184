import { injectable } from 'inversify'
import BaseService from './BaseService'
import type {
  GetImageResponse,
  CreateImageRequest,
  CreateImageResponse
} from '../dtos/v2/Image'
import type { RefTypes } from './utils'
import { Paths } from '../constants/Paths'

@injectable()
export default class ImageService extends BaseService {
  get({ id }: RefTypes<{ id: string }>) {
    return this.apiClient.use<GetImageResponse>(
      () => this.url(Paths.ImageById, { id }, true)
    )
  }

  async create({ provider = 'Cloudinary', providerImageId, attributes }: CreateImageRequest) {
    return await this.apiClient.post<CreateImageResponse, CreateImageRequest>(
      this.url(Paths.Image), { provider, providerImageId, attributes }
    )
  }
}
