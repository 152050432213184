/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/**
 * Manages early bundle (cookies, gtag)
 */
import { createGTM } from './gtm'

const GA_ID = 'UA-10829659-3'

/** Set up GA4 with proper consent controls */
const dataLayer = window.dataLayer = window.dataLayer || []
/**
 * We set this up before GA4 loads, so that we can push initial state
 */
window.hasAnalyticsConsent = false

{
  const gtag = window.gtag = function(...args) { dataLayer.push(arguments) }
  /** @see https://www.civicuk.com/cookie-control/documentation/optional-categories#google-analytics-4-consent-mode */
  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'denied'
  })
  window.grantConsent = () => {
    const { gtag } = window
    gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted'
    })
    window.hasAnalyticsConsent = true
  }
}

{
  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`
  script.onload = () => {
    const { gtag } = window
    gtag('js', new Date())
    gtag('config', GA_ID)
    gtag('get', GA_ID, 'client_id', (clientId: string) => {
      window.gaClientId = clientId
      window.SEGMENT_EVENTS?.forEach((fn) => { fn(clientId) })
      window.SEGMENT_EVENTS = []
    })
  }
  document.head.appendChild(script)
}

function consentableAnalyticsScripts() {
  /**
   * Both Google Analytics and Segment will be ready asynchronously,
   * so we call this function after each to make the identify call
   * once both are loaded.
   */

  /* Google Tag Manager: Analytics */
  createGTM('GTM-W25VX4')
  /* End Google Tag Manager: Analytics */

  /**
   * Google Analytics (Gtag)
   */
  const { gtag } = window
  gtag('consent', 'update', {
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: 'granted',
    security_storage: 'granted'
  })
  window.hasAnalyticsConsent = true
  /* End Gtag */
}

function consentableMarketingScripts() {
  const { gtag } = window
  gtag('consent', 'update', {
    ad_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: 'granted',
    security_storage: 'granted'
  })
  createGTM('GTM-P4M2XDM')
}

/** Hide top-bar-notice during the rest of the session */
const existingSession = !!sessionStorage.getItem('booklinker-session')
sessionStorage.setItem('booklinker-session', 'true')

if (!existingSession) {
  document.body.classList.add('show-top-bar')
}

const config = {
  apiKey: 'dd61620418d9309689d83001793f38f3379b80b9',
  product: 'PRO_MULTISITE',
  initialState: 'NOTIFY',
  locales: [
    {
      mode: 'ccpa',
      location: ['US-CA']
    }
  ],
  ccpaConfig: {
    description: 'This may include the website or our third-party tools processing personal data. '
        + 'In the case of your personal data, you may opt out of such activity by using the buttons below. '
        + 'To find out more about the categories of personal information collected and the purposes for which '
        + 'such information will be used, please refer to our',
    name: 'Privacy Policy',
    url: 'https://booklinker.com/terms',
    updated: '07/06/2018' // DD/MM/YYYY
  },
  optionalCookies: [
    {
      name: 'analytics',
      label: 'Analytics',
      description: 'Helps us measure website traffic.',
      recommendedState: 'on',
      cookies: [
        'ajs_user_id', // Segment
        '_ga', '_gid', '_gat', '__utma', '__utmt', '__utmb', '__utmc', '__utmz', '__utmv', '__cfduid', 'fr', 'personalization_id'], // GA
      onAccept: consentableAnalyticsScripts,
      onRevoke: function() {
        try {
          void window.analytics?.reset() // Segment
        } catch (e) {}
      }
    },
    {
      name: 'marketing',
      label: 'Marketing',
      description: 'Helps us measure the effectiveness of our promotions.',
      initialConsentState: 'on',
      cookies: ['_fbp', '_gid', '_ga'],
      onAccept: consentableMarketingScripts,
      onRevoke: function() {}
    }
  ],
  layout: 'POPUP',
  position: 'LEFT',
  theme: 'DARK',
  branding: {
    fontColor: '#FFF',
    fontSizeTitle: '1.0em',
    fontSizeIntro: '1.0em',
    fontSizeHeaders: '1.0em',
    fontSize: '.9em',
    backgroundColor: '#2b2b33',
    toggleText: '#fff',
    toggleColor: '#F96259',
    toggleBackground: '#43434c',
    removeIcon: true,
    removeAbout: true
  },
  excludedCountries: ['all']
}

{
  const script = document.createElement('script')
  script.src = 'https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js'
  script.onload = () => {
    CookieControl.load(config)
  }
  document.head.appendChild(script)
}
