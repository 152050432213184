import { injectable, inject } from 'inversify'
import IDENTIFIERS from '../shared/inversify.types'
import IApiClientFactory from '../shared/IApiClientFactory'
import IAuthSessionStorage from '../shared/IAuthSessionStorage'
import type SignUpResponse from '../dtos/v1/SignUpResponse'
import type AuthLinkResponse from '../dtos/v1/AuthLinkResponse'
import RetailerConstants from '../constants/RetailerConstants'
import AuthSession from '../models/AuthSession'
import SegmentProxy from '../shared/SegmentProxy'
import BaseService from './BaseService'
import type AuthSessionResponse from '../dtos/AuthSessionResponse'
import flatry from 'flatry'
import { Paths } from '../constants/Paths'
import FetchCache from '../shared/FetchCache'

@injectable()
export default class AuthService extends BaseService {
  private readonly _authSessionStorage: IAuthSessionStorage

  constructor(
  @inject(IDENTIFIERS.ApiClientFactory) apiClientFactory: IApiClientFactory,
    @inject(IDENTIFIERS.AuthSessionStorage) authSessionStorage: IAuthSessionStorage) {
    super(apiClientFactory)
    this._authSessionStorage = authSessionStorage
  }

  async login(token: string): Promise<AuthSession> {
    const [err, res] = await flatry(
      this.apiClient.post<AuthSessionResponse>(
        Paths.AuthLogin, { token }
      )
    )

    if (err) {
      const authSession = new AuthSession()
      this._authSessionStorage.set(authSession)
      return authSession
    }

    const sessionId = res.sessionId
    const hasSessionId = sessionId != null && sessionId.trim() !== ''
    let hasErrors = false

    if (res.responseStatus != null) {
      const responseStatus = res.responseStatus
      hasErrors = responseStatus.errorCode != null && responseStatus.errorCode.trim() !== ''
    }

    const isAuthenticated = hasSessionId && !hasErrors
    let authSession: AuthSession
    if (isAuthenticated) {
      authSession = new AuthSession(true, res.userName, res.userId)
      window.grantConsent()
      /**
       * Reset useSWRV fetch cache so we don't
       * re-use from another user's session
       */
      FetchCache.reset()
      SegmentProxy.identify(res.userId, {
        email: res.userName
      })
      SegmentProxy.alias(res.userId, res.userName)
    } else {
      authSession = new AuthSession()
    }
    this._authSessionStorage.set(authSession)

    return authSession
  }

  async signup(email: string, url: string, code: string, appleAffiliateToken: string): Promise<SignUpResponse> {
    const parameters: Record<string, string> = {}
    if (appleAffiliateToken?.trim() !== '') {
      parameters[RetailerConstants.AppleServices.ParameterName] = appleAffiliateToken
    }

    return await this.apiClient.post<SignUpResponse>(
      Paths.AuthSignup, {
        email,
        url,
        code,
        // hardcoded because we only support Apple affiliate token for now
        affiliateProgramGuid: RetailerConstants.AppleServices.ProgramGuid,
        affiliateParameters: parameters
      }
    )
  }

  async signupLink(email: string): Promise<AuthLinkResponse> {
    return await this.apiClient.post<AuthLinkResponse>(
      Paths.AuthSignupLink, { email }
    )
  }
}
