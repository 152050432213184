export { }
declare global {
  export interface Event {
    isEnterKey(): boolean
  }
}

Event.prototype.isEnterKey = function(this: Event): boolean {
  return this != null && (this instanceof KeyboardEvent) && (this).code?.toUpperCase() === 'ENTER'
}
