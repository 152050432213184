// InversifyJS needs to use the type as identifiers at runtime
const IDENTIFIERS = {
  ApiClientFactory: Symbol.for('ApiClientFactory'),
  AuthSessionStorage: Symbol.for('AuthSessionStorage'),
  AdminService: Symbol.for('AdminService'),
  AccountService: Symbol.for('AccountService'),
  LinkMetadataService: Symbol.for('LinkMetadataService'),
  ImageService: Symbol.for('ImageService'),
  AuthService: Symbol.for('AuthService'),
  LinkService: Symbol.for('LinkService'),
  LinkIssueReportService: Symbol.for('LinkIssueReportService'),
  LinksReportService: Symbol.for('LinksReportService'),
  AffiliateSettingsService: Symbol.for('AffiliateSettingsService'),
  GeniuslinkUpgradeService: Symbol.for('GeniuslinkUpgradeService'),
  UserSubscriptionService: Symbol.for('UserSubscriptionService'),
  // Partnerize - Apple Service
  AppleServicesUserService: Symbol.for('AppleServicesUserService'),
  AppleServicesTermsService: Symbol.for('AppleServicesTermsService')
}

export default IDENTIFIERS
