export default class PutLinkUpdateRequest {
  domain: string
  code: string
  baseCode: string
  url: string

  constructor(domain: string, code: string, baseCode: string, url: string) {
    this.domain = domain
    this.code = code
    this.baseCode = baseCode
    this.url = url
  }
}
