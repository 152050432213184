/* eslint-disable no-extend-native */
import EmailValidator from '../EmailValidator'

export { }
declare global {
  export interface String {
    isValidEmail(): boolean
    isWholeNumber(): boolean
    tryParseIsbn(this: string, out: (result: string) => void): boolean
    addProtocolToUrl(): string
    tryParseUrl(this: string, out: (result: string) => void): boolean
  }
}

String.prototype.isValidEmail = function(this: string): boolean {
  return EmailValidator.isValidEmail(this?.trim())
}

String.prototype.isWholeNumber = function(this: string): boolean {
  try {
    if (this.includes('.')) {
      return false
    }
    if (!Number.isNaN(Number(this))) {
      const num = Number.parseInt(this)
      return Number.isInteger(num) && Math.sign(num) === 1
    }
    return false
  } catch {
    return false
  }
}

/**
 * @param s Trimmed string
 */
function isValidIsbn13(s: string): boolean {
  return s.length === 13 && s.isWholeNumber() && /^97[89]/.test(s)
}

function isValidIsbn10(s: string): boolean {
  if (s.length === 10) {
    if (s.isWholeNumber()) {
      return true
    }
    if (s[9].toLowerCase() === 'x' && s.substring(0, 9).isWholeNumber()) {
      return true
    }
  }
  return false
}

String.prototype.tryParseIsbn = function(this: string, out: (result: string) => void): boolean {
  try {
    const value = this.replace(/(-| )+/g, '')
    if (value && (isValidIsbn13(value) || isValidIsbn10(value))) {
      out(value)
      return true
    };
    return false
  } catch {
    return false
  }
}

String.prototype.addProtocolToUrl = function(this: string): string {
  if (!/^(?:f|ht)tps?:\/\//.test(this)) {
    return 'https://' + this
  }
  return this
}

String.prototype.tryParseUrl = function(this: string, out: (result: string) => void): boolean {
  const urlCandidate = this.addProtocolToUrl()
  if (!urlCandidate?.includes('.')) {
    return false
  }
  try {
    const url = new URL(urlCandidate)
    if (urlCandidate !== '' && url.origin !== '' && !url.username) {
      out(urlCandidate)
      return true
    }
    return false
  } catch {
    return false
  }
}
