import { injectable } from 'inversify'
import BaseService from './BaseService'
import { Paths } from '../constants/Paths'

@injectable()
export default class UserSubscriptionService extends BaseService {
  async subscribe() {
    return await this.apiClient.post<string>(
      Paths.UserSubscription, {}
    )
  }
}
