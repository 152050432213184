import IApiClientFactory from '../shared/IApiClientFactory'
import IDENTIFIERS from '../shared/inversify.types'
import { injectable, inject } from 'inversify'

@injectable()
export default class BaseService {
  readonly _apiClientFactory: IApiClientFactory

  constructor(@inject(IDENTIFIERS.ApiClientFactory) apiClientFactory: IApiClientFactory) {
    this._apiClientFactory = apiClientFactory
  }

  /**
   * Ideally, we wouldn't use a factory, except for the unexplained warning
   * in APIClient. ¯\_(ツ)_/¯
   */
  get apiClient() {
    return this._apiClientFactory.getApiClient()
  }

  get url() {
    return this.apiClient.url
  }
}
