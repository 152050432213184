import { injectable } from 'inversify'
import type GetProductMetadataResponse from '../dtos/v1/GetProductMetadataResponse'
import type GetShortLinkMetadataResponse from '../dtos/v1/GetShortLinkMetadataResponse'
import BaseService from './BaseService'
import { Paths } from '../constants/Paths'

@injectable()
export default class LinkMetadataService extends BaseService {
  getShortUrlMetadata(domain: string, code: string) {
    return this.apiClient.use<GetShortLinkMetadataResponse>(
      this.url(Paths.LinkShortUrlMetadata, { domain, code })
    )
  }

  async getProductMetadata(url: string) {
    return await this.apiClient.get<GetProductMetadataResponse>(
      this.url(Paths.LinkProductMetadata, { url })
    )
  }

  async getIsbnMetadata(isbn: string) {
    return await this.apiClient.get<GetProductMetadataResponse>(
      this.url(Paths.LinkIsbnMetadata, { isbn })
    )
  }
}

export type ILinkMetadataService = Omit<LinkMetadataService, '_apiClientFactory'>
