// hardcoded because we're not retrieving retailer info from the API just yet
// we also only will only support a handful to start
const RetailerConstants = {
  AppleServices: {
    ProgramGuid: '85a33651-7e09-4dbd-8fa9-9895f9eedc8c',
    ParameterName: 'at',
    SignUpLink: 'https://appleservices-console.partnerize.com/login/appleservices/en_us',
    IsValidRegex: '^1.*$'
  },
  Unsupported: {
    ProgramGuid: '00000000-0000-0000-0000-000000000000'
  }
}

export default RetailerConstants
