import type { ErrorResponse } from '@servicestack/client'
import { HttpMethods, JsonServiceClient } from '@servicestack/client'
import container from '../../../inversify.config'
import IDENTIFIERS from './inversify.types'
import type { SWRResponse } from './IApiClient'
import type IAuthSessionStorage from './IAuthSessionStorage'
import AuthSession from '../models/AuthSession'
import type AuthSessionResponse from '../dtos/AuthSessionResponse'
import env from '/env.js'
import useSWRV from 'swrv'
import FetchCache from './FetchCache'
import type { IKey } from 'swrv/dist/types'
import { reactive, computed, type ComputedRef, unref, ref, watch } from 'vue'
import router from '@/Router'
import { rollbar } from '@/rollbar'
import isPlainObject from 'lodash-es/isPlainObject'

export interface ErrorResponseWithStatus extends ErrorResponse {
  responseStatus: ErrorResponse['responseStatus'] & {
    statusCode: number
  }
  error: Record<string, string>
}

export const isErrorResponse = (err: any): err is ErrorResponseWithStatus => {
  return isPlainObject(err)
    && 'responseStatus' in err
    && 'error' in err
}

function toCamelCase(str: string) {
  return str.charAt(0).toLowerCase() + str.substring(1)
}
/**
 * JsonServiceClient is NOT meant to be used for the entirety of the app lifecycle
 * Do not reuse. Instead, resolve ApiClientFactory (via Inversify) and get a new
 * ApiClient instance for single usages each time
 *
 * @todo - I really wish there was an explanation or link as to why the client
 *         can't be re-used, since this seems contrary to ServiceStack's own
 *         documentation.
 */
export class ApiClient {
  private readonly UnauthedErrorCodes: string[]
  private readonly AuthSessionStorage: IAuthSessionStorage
  private readonly _client: JsonServiceClient

  constructor() {
    /**
     * Using this instead of extends so that we can
     * define our own `post`, `get`, and `patch` methods
     */
    this._client = new JsonServiceClient()
    this.UnauthedErrorCodes = ['401', '403']
    this.AuthSessionStorage = container.get<IAuthSessionStorage>(IDENTIFIERS.AuthSessionStorage)
  }

  async auth(): Promise<AuthSessionResponse> {
    return await this._client.send<AuthSessionResponse>(HttpMethods.Post, {}, null, `${env.BL_API_HOSTNAME}/auth`)
  }

  /**
   * Will be executed after an error has been returned.
   * We want to kick unauthed users (e.g. if they've been logged out of all devices)
   * if we got 401/403 responses when accessing endpoints that require auth
   */
  handleError(err: ErrorResponseWithStatus | undefined) {
    if (err) {
      const statusCode = String(err.responseStatus?.statusCode ?? '')
      if (statusCode.startsWith('5')) {
        rollbar.error('API error', err.responseStatus)
      }
      if (this.UnauthedErrorCodes.includes(statusCode)) {
        this.AuthSessionStorage.set(new AuthSession())
        void router.push('/')
      }
      err.error = {}
      err.responseStatus.errors?.forEach((error) => {
        err.error[toCamelCase(error.fieldName)] = error.message
      })
      if (err.responseStatus.errors?.length === 0) {
        err.error.unknown = 'An unknown error occurred. Please contact support.'
      }
    }
  }

  setHeaders() {
    const impersonateAs = this.AuthSessionStorage.get().impersonateAs
    if (impersonateAs?.length > 0) {
      if (this._client.headers == null) this._client.headers = new Headers()
      this._client.headers.set('X-Impersonate', impersonateAs)
    }
  }

  /**
   * @param url URL with or without protocol
   * @param params Parameters to be appended to the URL
   * @param returnIfNull Will return false if any parameters are null
   */
  url(url: string): string
  url(url: string, params: Record<string, any>): string
  url(url: string, params: Record<string, any>, returnIfNull: true): string | false
  url(url: string, params?: Record<string, any>, returnIfNull?: true) {
    if (!url || !(typeof url === 'string')) {
      return ''
    }
    if (!url.includes('http') && env.BL_API_HOSTNAME) {
      url = env.BL_API_HOSTNAME + url
    }
    if (!params) {
      return url
    }
    /**
     * Track if any parameters are explicitly null,
     * in which case this method returns `false` to
     * `useSWRV` so that it doesn't try to fetch.
     */
    let hasNullValue = false
    const str = Object.entries(params)
      .filter(
        /** Remove undefined */
        ([key, value]) => value !== undefined
      )
      .reduce<string[]>(
      (acc, [key, value]) => {
        value = unref(value)
        if (value === null) {
          hasNullValue = true
          /**
           * We should not have an explicit `null` AND an expected url parameter
           */
          if (url.includes(`:${key}`)) {
            throw new Error('Expected url parameter')
          }
        } else {
          /** Supports express-like urls like `/path/:id` */
          if (url.includes(`:${key}`)) {
            url = url.replace(`:${key}`, value)
          } else {
            acc.push(`${key}=${encodeURIComponent(value)}`)
          }
        }
        return acc
      }, [])
      .join('&')

    if (returnIfNull && hasNullValue) {
      return false
    }

    if (str) {
      return `${url}?${str}`
    }
    return url
  }

  /**
   * Newer versions of ServiceStack don't require
   * the method. (It's inferred from the request)
   */
  async send<T>(method: string, request: any | null, args?: any, url?: string): Promise<T> {
    this.setHeaders()

    if (!url) {
      return await Promise.reject<T>(new Error('No URL provided'))
    }

    const promise = this._client.send<T>(method, request, args, this.url(url))
    /**
     * Will be executed after the caller's .catch() block has been executed
     * we want to kick unauthed users (e.g. if they've been logged out of all devices)
     * if we got 401/403 responses when accessing endpoints that require auth
     */
    promise.catch((err: ErrorResponse) => { this.handleError(err as ErrorResponseWithStatus) })

    return await Promise.resolve<T>(promise)
  }

  async get<T>(url: string): Promise<T> {
    return await this.send<T>(HttpMethods.Get, {}, null, url)
  }

  async post<T, U extends Record<string, any> = Record<string, any>>(url: string, payload: U): Promise<T> {
    return await this.send<T>(HttpMethods.Post, payload, null, url)
  }

  async put<T, U extends Record<string, any> = Record<string, any>>(url: string, payload: U): Promise<T> {
    return await this.send<T>(HttpMethods.Put, payload, null, url)
  }

  async patch<T, U extends Record<string, any> = Record<string, any>>(url: string, payload: U): Promise<T> {
    return await this.send<T>(HttpMethods.Patch, payload, null, url)
  }

  async delete<T>(url: string): Promise<T> {
    return await this.send<T>(HttpMethods.Delete, {}, null, url)
  }

  use<T extends Record<string, any>, DataKey extends string = 'data'>(url: string | (() => string | false | null | undefined), dataKey: DataKey = 'data' as DataKey): SWRResponse<T, DataKey> {
    this.setHeaders()

    let promiseResolve: (value: T) => void
    let promiseReject: (reason?: any) => void
    const promise = new Promise((resolve, reject) => {
      promiseResolve = resolve
      promiseReject = reject
    })
    /**
     * Just so we don't get confused in here
     * `T` is the shape of the entire response object.
     * In new APIs, `T['data']` will be returned
     * from this method on the data key. For older APIs, `data` will
     * point to the entire response object.
     *
     * E.g.
     *  Response: { field1: 'foo', field2: 'bar' }
     *  Return: { data: { field1: 'foo', field2: 'bar' } }
     *
     *  Response: { data: { field1: 'foo', field2: 'bar' } }
     *  Return: { data: { field1: 'foo', field2: 'bar' } }
     */
    const isFetching = ref(false)
    const {
      data,
      error,
      isValidating,
      mutate
    } = useSWRV<T, ErrorResponse>(
      url as IKey,
      /**
       * If the URL resolver function is falsey the first time
       * no fetching will occur.
       * @see https://docs-swrv.netlify.app/features.html#dependent-fetching
       */
      async resolvedUrl => {
        isFetching.value = true
        return await this.send<T>(HttpMethods.Get, {}, null, resolvedUrl)
      },
      {
        errorRetryCount: 3,
        revalidateOnFocus: false,
        cache: FetchCache.get()
      }
    )

    const isLoading = computed(() => isFetching.value && data.value === undefined && !error.value)

    /**
     * If the root object contains 'data', then we
     * want to return that instead of the root object,
     * to avoid referencing `data.data` in the component.
     */
    const dataRoot = computed(() =>
      (data.value && dataKey in data.value) ? data.value[dataKey] : data.value
    ) as ComputedRef<SWRResponse<T, DataKey>['data']>
    const page = computed(() => data.value?.page)

    /** Creates a promise that can be awaited on the return */
    watch(dataRoot, data => {
      if (data) {
        promiseResolve(data)
      }
    })
    watch(error, err => {
      if (err) {
        promiseReject(err)
      }
    })

    /**
     * Note that because this object is reactive, it can produce a state
     * where it has data AND has an error, because it preserves `data`
     * until `data` is explicitly updated with a new value.
     */
    const res = reactive({
      data: dataRoot,
      page,
      error,
      isValidating,
      isLoading,
      mutate,
      promise
    })

    return res
  }
}

export default ApiClient
