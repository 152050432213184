import { injectable } from 'inversify'
import type PostGeniuslinkUpgradeResponse from '../dtos/v1/PostGeniuslinkUpgradeResponse'
import BaseService from './BaseService'
import { Paths } from '../constants/Paths'

@injectable()
export default class GeniuslinkUpgradeService extends BaseService {
  async sendUpgradeRequest() {
    return await this.apiClient.post<PostGeniuslinkUpgradeResponse>(
      Paths.GeniusLinkUpgrade, {}
    )
  }
}
