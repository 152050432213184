import { isBrowser } from 'is-where'
import env from '/env.js'

type FetchParams = Parameters<typeof window.fetch>

if (isBrowser()) {
  /**
   * Patch fetch to return the status code to the ServiceStack client
   */
  const originalFetch = window.fetch

  window.fetch = async (...args: FetchParams) => {
    const [resource] = args
    let url = (resource instanceof Request ? resource.url : resource)
    url = url instanceof URL ? url.toString() : url
    if (!url.startsWith(env.BL_API_HOSTNAME)) {
      return await originalFetch(...args)
    }
    let response = await originalFetch(...args)

    /** Fetch didn't fail, but the response might not have a body */
    let json: Record<string, any>
    try {
      json = await response.json()
    } catch (e) {
      json = {}
    }
    json.responseStatus = {
      ...json.responseStatus,
      statusCode: response.status
    }
    response = new Response(JSON.stringify(json), {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers
    })
    return response
  }
}
