import { injectable } from 'inversify'
import type { GetLinkReportResponse } from '../dtos/v1/GetLinkReportResponse'
import { type Ref } from 'vue'
import BaseService from './BaseService'
import { Paths } from '../constants/Paths'

@injectable()
export default class LinksReportService extends BaseService {
  getLinkReport(baseCode: string | Ref<string | null>) {
    return this.apiClient.use<GetLinkReportResponse>(
      () => this.url(Paths.LinkReport, { baseCode }, true)
    )
  }
}
