const enum LinkEditorState {
  // default state - not creating/editing link
  NONE = '',
  // editing link
  EDITING = 'editing',
  // can customize link (need to input email address if logged out)
  CUSTOMIZING = 'customizing',
  // prompt user activation
  ACTIVATING = 'activating',
  // link is created
  CREATED = 'created'
};

export default LinkEditorState
