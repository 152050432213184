import { injectable } from 'inversify'
import BaseService from './BaseService'
import type {
  UpdateAccountRequest,
  UpdateAccountResponse,
  GetAccountResponse
} from '../dtos/v2/Account'
import { Paths } from '../constants/Paths'

@injectable()
export default class AccountService extends BaseService {
  get() {
    return this.apiClient.use<GetAccountResponse>(
      this.url(Paths.Account)
    )
  }

  async update(settings: UpdateAccountRequest) {
    return await this.apiClient.put<UpdateAccountResponse, UpdateAccountRequest>(
      this.url(Paths.Account), settings
    )
  }
}
