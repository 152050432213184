import { Container } from 'inversify'
import IDENTIFIERS from './src/scripts/shared/inversify.types'
import type IApiClientFactory from './src/scripts/shared/IApiClientFactory'
import ApiClientFactory from './src/scripts/shared/ApiClientFactory'
import type IAuthSessionStorage from './src/scripts/shared/IAuthSessionStorage'
import AuthSessionStorage from './src/scripts/shared/AuthSessionStorage'
import type IAuthService from './src/scripts/services/interfaces/IAuthService'
import AuthService from './src/scripts/services/AuthService'
import type ILinkService from './src/scripts/services/interfaces/ILinkService'
import LinkService from './src/scripts/services/LinkService'
import type ILinkMetadataService from './src/scripts/services/interfaces/ILinkMetadataService'
import LinkMetadataService from './src/scripts/services/LinkMetadataService'
import type ILinksReportService from './src/scripts/services/interfaces/ILinksReportService'
import LinksReportService from './src/scripts/services/LinksReportService'
import type IAffiliateTokensService from './src/scripts/services/interfaces/IAffiliateSettingsService'
import AffiliateSettingsService from './src/scripts/services/AffiliateSettingsService'
import type IGeniuslinkUpgradeService from './src/scripts/services/interfaces/IGeniuslinkUpgradeService'
import GeniuslinkUpgradeService from './src/scripts/services/GeniuslinkUpgradeService'
import type IAccountService from './src/scripts/services/interfaces/IAccountService'
import AccountService from './src/scripts/services/AccountService'
import type IUserSubscriptionService from './src/scripts/services/interfaces/IUserSubscriptionService'
import UserSubscriptionService from './src/scripts/services/UserSubscriptionService'
import type ILinkIssueReportService from './src/scripts/services/interfaces/ILinkIssueReportService'
import LinkIssueReportService from './src/scripts/services/LinkIssueReportService'
import type IAdminService from './src/scripts/services/interfaces/IAdminService'
import AdminService from './src/scripts/services/AdminService'
import type IImageService from '@/scripts/services/interfaces/IImageService'
import ImageService from '@/scripts/services/ImageService'
import type IAppleServicesUserService from '@/scripts/services/Partnerize/IAppleServicesUserService'
import AppleServicesUserService from '@/scripts/services/Partnerize/AppleServicesUserService'
import type IAppleServicesTermsService from '@/scripts/services/Partnerize/IAppleServicesTermsService'
import AppleServicesTermsService from '@/scripts/services/Partnerize/AppleServicesTermsService'

/**
 * For more info:
 * https://inversify.io/
 * https://github.com/inversify/InversifyJS
 */

const container: Container = new Container()

/**
 * Exported to use mocked http responses in tests
 * instead of mocked services so we're not over-mocking.
 *
 * Eventually we can remove Inversify.
 */
export function bindAllServices() {
  container.unbindAll()
  container.bind<IApiClientFactory>(IDENTIFIERS.ApiClientFactory).to(ApiClientFactory).inSingletonScope()
  container.bind<IAuthSessionStorage>(IDENTIFIERS.AuthSessionStorage).to(AuthSessionStorage).inSingletonScope()
  container.bind<IAdminService>(IDENTIFIERS.AdminService).to(AdminService).inSingletonScope()
  container.bind<IAccountService>(IDENTIFIERS.AccountService).to(AccountService).inSingletonScope()
  container.bind<IImageService>(IDENTIFIERS.ImageService).to(ImageService).inSingletonScope()
  container.bind<ILinkMetadataService>(IDENTIFIERS.LinkMetadataService).to(LinkMetadataService).inSingletonScope()
  container.bind<IAuthService>(IDENTIFIERS.AuthService).to(AuthService).inSingletonScope()
  container.bind<ILinkService>(IDENTIFIERS.LinkService).to(LinkService).inSingletonScope()
  container.bind<ILinkIssueReportService>(IDENTIFIERS.LinkIssueReportService).to(LinkIssueReportService).inSingletonScope()
  container.bind<ILinksReportService>(IDENTIFIERS.LinksReportService).to(LinksReportService).inSingletonScope()
  container.bind<IAffiliateTokensService>(IDENTIFIERS.AffiliateSettingsService).to(AffiliateSettingsService).inSingletonScope()
  container.bind<IGeniuslinkUpgradeService>(IDENTIFIERS.GeniuslinkUpgradeService).to(GeniuslinkUpgradeService).inSingletonScope()
  container.bind<IUserSubscriptionService>(IDENTIFIERS.UserSubscriptionService).to(UserSubscriptionService).inSingletonScope()
  // Partnerize - Apple Services
  container.bind<IAppleServicesUserService>(IDENTIFIERS.AppleServicesUserService).to(AppleServicesUserService).inSingletonScope()
  container.bind<IAppleServicesTermsService>(IDENTIFIERS.AppleServicesTermsService).to(AppleServicesTermsService).inSingletonScope()
}
bindAllServices()

export default container
