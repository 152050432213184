import type { RouteRecordRaw } from 'vue-router'
// Use `() => import("/path/to/component")` to lazy-load component
const routes = [
  /* Old site redirects */
  { path: '/login.php', redirect: '/login' },
  { path: '/help.php', redirect: '/faq' },
  { path: '/faq.php', redirect: '/faq' },
  { path: '/upgrade.php', redirect: '/upgrade' },
  { path: '/terms.php', redirect: '/terms' },
  {
    // Catch-all for old paths - redirect to home
    path: '/:path(author-links|book-links|createlink|createlink2|createlink3|error|index|myaccount|mylinks|passrecovery).php',
    redirect: '/'
  },
  /* Main routes */
  {
    path: '/',
    name: 'Home',
    component: async () => await import('./templates/Home.vue')
  },
  {
    path: '/links',
    name: 'Links',
    component: async () => await import('@/views/links/Links.vue'),
    meta: {
      title: 'Your links',
      requiresAuth: true
    },
    redirect: '/links/books',
    children: [
      {
        path: 'books',
        name: 'Books',
        component: async () => await import('@/views/links/BooksOrCollections.vue')
      },
      {
        path: 'collections',
        name: 'Collections',
        component: async () => await import('@/views/links/BooksOrCollections.vue')
      },
      {
        path: 'reports/:domain/:code',
        name: 'Reports',
        component: async () => await import('@/views/links/Reports.vue')
      }
    ]
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: async () => await import('./templates/Privacy.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: async () => await import('./templates/Terms.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: async () => await import('./views/AffiliationSettings.vue'),
    meta: {
      requiresAuth: true,
      title: 'Affiliate Settings'
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: async () => await import('@/views/Account.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: async () => await import('./templates/Faq.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: async () => await import('./templates/About.vue')
  },
  {
    path: '/feature-collections',
    name: 'CollectionsFeature',
    component: async () => await import('./templates/CollectionsFeature.vue')
  },
  {
    path: '/affiliate-disclosure',
    name: 'AffiliateDisclosure',
    component: async () => await import('./templates/AffiliateDisclosure.vue')
  },
  {
    path: '/upgrade',
    name: 'Upgrade',
    component: async () => await import('./templates/Upgrade.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    meta: { external: true }
  } as unknown as RouteRecordRaw,
  {
    path: '/confirm/:token',
    name: 'RegistrationConfirmation',
    component: async () => await import('./templates/RegistrationConfirmation.vue')
  },
  {
    path: '/login',
    name: 'LoginForm',
    component: async () => await import('./templates/LoginForm.vue'),
    meta: { excludeFromSitemap: true }
  },
  {
    path: '/login/:token',
    name: 'LoggingIn',
    component: async () => await import('./templates/Login.vue')
  },
  {
    path: '/logout',
    name: 'LoggingOut',
    component: async () => await import('./templates/Logout.vue'),
    meta: { excludeFromSitemap: true }
  },
  {
    path: '/report-link/:domain/:code',
    name: 'ReportLink',
    component: async () => await import('./templates/ReportLink.vue'),
    meta: { excludeFromSitemap: true }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: async () => await import('./templates/404.vue')
  },
  /* Admin only */
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: async () => await import('./templates/admin/AdminDashboard.vue'),
    meta: { requiresAuth: true, adminOnly: true }
  }
] satisfies RouteRecordRaw[]

/*
  Get paths for sitemap - only paths with no path params, no redirects, and no auth required
  e.g. /login/:token, /:catchAll(.*), and /admin are not included
*/
const paths = Object.values(routes)
  .filter(x => !x.path.includes(':') && x.redirect === undefined
            && (x.meta === undefined || (x.meta.requiresAuth !== true && x.meta.excludeFromSitemap !== true)))
  .map(x => x.path)

export { routes, paths }
