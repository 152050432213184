<template>
  <template v-if="isSessionInitialized">
    <div
      class="container"
      :class="{ 'hidden': showNavigationBar }"
    >
      <div class="bl-header-container">
        <div class="bl-nav-container">
          <!-- BS nav -->
          <nav class="navbar navbar-expand-lg navbar-light">
            <div class="help-container">
              <RouterLink to="/faq">
                <span class="icon-help"></span>
              </RouterLink>
            </div>

            <div class="container-fluid">
              <RouterLink
                to="/"
                class="navbar-brand"
              >
                <img
                  src="../assets/img/booklinker-logo.svg"
                  width="144"
                >
              </RouterLink>
              <div class="navbar-nav">
                <template
                  v-for="link in mainLinks"
                  :key="link.name"
                >
                  <RouterLink
                    class="nav-link"
                    :class="{ active: isActivePath(link.path) }"
                    :to="link.path"
                  >
                    {{ link.name }}
                  </RouterLink>
                </template>
              </div>

              <div class="main-menu-mobile">
                <button
                  class="navbar-toggler"
                  type="button"
                  id="main-menu"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>

                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="main-menu"
                >
                  <li
                    v-for="link in mainLinks"
                    :key="link.name"
                  >
                    <RouterLink
                      class="dropdown-item"
                      :to="link.path"
                    >
                      {{ link.name }}
                    </RouterLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <!--end BS nav -->
        </div>

        <div class="avatar-container">
          <div v-if="!authSession.isAuthenticated">
            <RouterLink
              to="/login"
              class="avatar-placeholder"
            >
              <img src="../assets/img/avatar-placeholder.svg" />
            </RouterLink>
          </div>
          <div
            class="dropdown"
            v-if="authSession.isAuthenticated"
          >
            <button
              class="avatar-bg"
              id="avatar-menu"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div
                class="avatar-image"
                :style="[userImageUrl.length > 0? {'background-image': 'url(' + userImageUrl + ')'} : {}]"
              ></div>
            </button>
            <ul
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="avatar-menu"
            >
              <li><span class="dropdown-item-text">Logged in as:<br>{{ userEmail }}</span></li>
              <li v-if="impersonateAs.length > 0">
                <span class="dropdown-item-text">Impersonating as:<br>{{ impersonateAs }}</span>
              </li>
              <li>
                <RouterLink
                  class="dropdown-item"
                  to="/links"
                >
                  Your links
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  class="dropdown-item"
                  to="/account"
                >
                  Account
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  class="dropdown-item"
                  to="/settings"
                >
                  Affiliate settings
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  class="dropdown-item"
                  to="/logout"
                >
                  Sign out
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <main class="page-body">
      <RouterView :key="$route.path" />
    </main>

    <footer class="page-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h3>Site map</h3>
            <ul class="site-map">
              <li
                v-for="link in sitemapLinks"
                :key="link.name"
              >
                <a :href="link.path">{{ link.name }}</a>
              </li>
              <li>
                <a
                  class="footerLink smallnavlink"
                  href="#!"
                  onclick="CookieControl.open()"
                >Manage cookie preferences
                  (EU and US-California)</a>
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <div class="copyright-area">
              <RouterLink to="/">
                <img
                  src="../assets/img/booklinker-logo.svg"
                  width="130"
                >
              </RouterLink>
              <p class="copyright">
                &copy; 2022 by GeoRiot Networks, Inc.
              </p>
              <div class="social">
                <a
                  href="https://www.twitter.com/Booklinker"
                  class="uicon uicon-twitter-bb"
                ></a>
                <a
                  href="https://www.facebook.com/booklinker/?fref=ts"
                  class="uicon uicon-facebook-bb"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </template>
  <ModalsContainer />
</template>

<style scoped>
.page-body {
  /* max-width: 500px; */
}
@media (min-width: 992px) {
  .navbar-expand-lg {
      padding-left: 0;
  }
}
</style>

<!-- TODO - convert to SFC -->

<script lang="ts" src="../scripts/components/App.ts"></script>
