import { AnalyticsBrowser } from '@segment/analytics-next'
import env from '/env.js'

const analytics = window.analytics = AnalyticsBrowser.load({
  writeKey: env.SEGMENT_ID
})

/**
 * Checks if we can use Segment
 */
const canSendAnalytics = (): boolean => analytics !== undefined && window.hasAnalyticsConsent

/** Create a queue of events until we resolve the Google Analytics clientId */
window.SEGMENT_EVENTS ??= []
const events = window.SEGMENT_EVENTS

export default {
  /**
   * Tie user email to Segment events/actions
   * @param userEmail
   */
  identify(...args: Parameters<AnalyticsBrowser['identify']>) {
    const { gaClientId } = window
    const [id, traits = {}, ...rest] = args
    const identify = (gaClientId: string) => {
      const defaultTraits = { gaClientId }
      void window.analytics.identify(id, { ...defaultTraits, ...traits }, ...rest)
    }
    if (canSendAnalytics()) {
      if (gaClientId) {
        identify(gaClientId)
      } else {
        events.push(identify)
      }
    }
  },

  alias(...args: Parameters<AnalyticsBrowser['alias']>) {
    if (canSendAnalytics()) {
      void window.analytics.alias(...args)
    }
  },

  /**
   * Track an event
   * @param eventName (see AnalyticsEvents for event names)
   */
  track(eventName: string, props: Parameters<AnalyticsBrowser['track']>[1] = {}) {
    const track = (gaClientId: string) => {
      const defaultProps = { gaClientId }
      void window.analytics.track(eventName, { ...defaultProps, ...props })
    }
    if (canSendAnalytics()) {
      if (window.gaClientId) {
        track(window.gaClientId)
      } else {
        events.push(track)
      }
    }
  },

  /**
   * Remove user ID from Segment - stops tracking
   */
  reset(): void {
    if (canSendAnalytics()) {
      void window.analytics.reset()
    }
  },

  /** Track page views */
  page(path?: string): void {
    if (canSendAnalytics()) {
      void window.analytics.page(path)
    }
  }
}
