import { type Ref, type InjectionKey } from 'vue'
import type LinkMetadata from '@/scripts/models/LinkMetadata'
import type AuthSession from '@/scripts/models/AuthSession'
import type LinkEditorState from '@/scripts/constants/LinkEditorStates'
import type { VueCookies } from 'vue-cookies'

const createSymbol = <T = unknown>(description: string) => {
  const sym = Symbol(description)
  return sym as InjectionKey<T>
}

/**
 * @todo - There probably is no real need for the provide / inject pattern.
 * The authSession and cookies will be global, so there is no need to
 * depend on the Vue component tree for contextual values. Other values
 * could probably be removed by changing the way components are composed.
 *
 * @note Symbols are used instead of strings to properly type the
 * injected values.
 *
 * @see https://vuejs.org/guide/typescript/composition-api.html#typing-provide-inject
 */
const Keys = {
  AuthSession: createSymbol<Ref<AuthSession>>('authSession'),
  LinkEditorState: createSymbol<Ref<LinkEditorState>>('linkEditorState'),
  Destinations: createSymbol<Ref<LinkMetadata[]>>('destinations'),
  InputUrl: createSymbol<Ref<string>>('inputUrl'),
  ParameterKeyValues: createSymbol<Ref<Record<string, string>>>('parameterKeyValues'),
  ProductMetadata: createSymbol<Ref<LinkMetadata>>('productMetadata'),
  Cookies: createSymbol<VueCookies>('$cookies')
}

export default Keys
