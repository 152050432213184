import { defineComponent, ref, provide } from 'vue'
import md5 from 'crypto-js/md5'
import container from '../../../inversify.config'
import IDENTIFIERS from '../shared/inversify.types'
import type IApiClientFactory from '../shared/IApiClientFactory'
import LinkEditorState from '../constants/LinkEditorStates'
import type IAuthSessionStorage from '../shared/IAuthSessionStorage'
import Keys from '../constants/InjectConstants'
import AuthSession from '../models/AuthSession'
import { ModalsContainer } from 'vue-final-modal'
import SegmentProxy from '../shared/SegmentProxy'

import '@/assets/sass/main.scss'
import 'vue-final-modal/style.css'

const App = defineComponent({
  name: 'App',
  components: { ModalsContainer },
  setup() {
    /**
     * https://v3.vuejs.org/guide/component-provide-inject.html#working-with-reactivity
     * 'provide/inject' bindings are not reactive by default.
     * We can change this behavior by passing a 'ref' property or 'reactive' object to 'provide'
     */
    const authSessionStorage = container.get<IAuthSessionStorage>(IDENTIFIERS.AuthSessionStorage)
    const apiClientFactory = container.get<IApiClientFactory>(IDENTIFIERS.ApiClientFactory)
    const linkEditorState = ref(LinkEditorState.NONE)
    const isSessionInitialized = ref(false)
    const authSession = ref(new AuthSession())
    // needs a .catch()?
    void authSessionStorage.checkAuthEndpoint(apiClientFactory.getApiClient()).then(() => {
      authSession.value = authSessionStorage.get()
      if (authSession.value.isAuthenticated) {
        window.grantConsent()
        SegmentProxy.identify(authSession.value.userId, {
          email: authSession.value.email
        })
        SegmentProxy.alias(authSession.value.userId, authSession.value.email)
      } else {
        SegmentProxy.identify()
      }
      isSessionInitialized.value = true
    })
    provide(Keys.LinkEditorState, linkEditorState)
    provide(Keys.AuthSession, authSession)

    return { authSessionStorage, apiClientFactory, linkEditorState, isSessionInitialized, authSession }
  },
  computed: {
    mainLinks(): any[] {
      return [
        { path: '/', name: 'Home' },
        { path: '/links', name: 'Your links', requiresAuth: true },
        { path: '/faq', name: 'FAQ' },
        { path: '/about', name: 'About' },
        { path: '/upgrade', name: 'Upgrade', requiresAuth: true },
        { path: '/blog', name: 'Blog' }
      ].filter(x => !x.requiresAuth || this.authSession.isAuthenticated)
    },
    sitemapLinks(): any[] {
      return this.mainLinks.concat([
        { path: '/settings', name: 'Affiliate settings', requiresAuth: true },
        { path: '/terms', name: 'Terms of Service' },
        { path: '/feature-collections', name: 'Introducing collections' },
        { path: '/privacy', name: 'Privacy Policy' }
      ]).filter(x => !x.requiresAuth || this.authSession.isAuthenticated)
    },
    impersonateAs(): string {
      return this.authSession?.impersonateAs?.trim() || ''
    },
    userEmail(): string {
      return this.authSession?.email?.trim() || ''
    },
    userImageUrl(): string {
      return this.userEmail.length > 0 ? `https://www.gravatar.com/avatar/${md5(this.userEmail.toLowerCase())}?d=retro` : ''
    },
    isLinkEditorOpen(): boolean {
      return this.linkEditorState !== LinkEditorState.NONE
    },
    showNavigationBar(): boolean {
      return this.isLinkEditorOpen
    }
  },
  methods: {
    isActivePath(path: string) {
      try {
        return path.toLowerCase() === window.location.pathname.toLowerCase()
      } catch {
        return false
      }
    }
  }
})

export default App
