export default class PostLinkCreationRequest {
  url: string
  code: string
  parameterKeyValues: Record<string, string>
  affiliatePrograms: Record<string, string>

  constructor(url: string, code: string, parameterKeyValues: Record<string, string>, affiliatePrograms: Record<string, any>) {
    this.url = url
    this.code = code
    this.parameterKeyValues = parameterKeyValues
    this.affiliatePrograms = affiliatePrograms
  }
}
