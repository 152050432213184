import 'reflect-metadata'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import { createApp } from 'vue'
import router from './Router'
import App from './templates/App.vue'
import VueCookies from 'vue-cookies'
import { createVfm } from 'vue-final-modal'
import { createHead } from '@unhead/vue'
import { rollbar } from './rollbar'

/**
 * Include extensions. Otherwise, runtime error will occur
 *
 * @todo - Mutating prototypes is considered a bad practice in JS / TS
 *         Not only can it cause unexpected conflicts, but it also effectively
 *         makes these functions "global variables", and it's an implicit / indirect
 *         dependency that ends up not being defined as an import in a file.
 *         This can additionally code splitting to not work effectively, because
 *         more things have a global scope.
 */
import './scripts/shared/extensions/NumberExtensions'
import './scripts/shared/extensions/StringExtensions'
import './scripts/shared/extensions/EventExtensions'
import './scripts/shared/extensions/fetch'

import ClipboardJS from 'clipboard'

// eslint-disable-next-line no-new
new ClipboardJS('.copy-button-wrapper')

const app = createApp(App)
const head = createHead()
app.use(createVfm())
app.use(head)

app.config.globalProperties.$rollbar = rollbar

app.config.errorHandler = (err: any) => {
  rollbar.error(err)
  console.error(err?.message ?? err)
}

app.use(VueCookies)
app.use(router).mount('#app')
