import { injectable } from 'inversify'
import type PostLinkIssueReportRequest from '../dtos/v1/PostLinkIssueReportRequest'
import type PostLinkIssueReportResponse from '../dtos/v1/PostLinkIssueReportResponse'
import BaseService from './BaseService'
import { Paths } from '../constants/Paths'

@injectable()
export default class LinkIssueReportService extends BaseService {
  async post(payload: PostLinkIssueReportRequest) {
    return await this.apiClient.post<PostLinkIssueReportResponse>(
      Paths.LinkIssueReport, payload
    )
  }
}
