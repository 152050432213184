import Rollbar from 'rollbar'
import env from '/env.js'

const environment = env.MODE ?? 'development'

export const rollbar = new Rollbar({
  accessToken: env.ROLLBAR_ACCESS_TOKEN,
  captureUncaught: false,
  captureUnhandledRejections: false,
  captureIp: 'anonymize',
  version: '1.0',
  payload: {
    environment
  },
  /**
   * Allows for source mapping for any domains
   * @see https://docs.rollbar.com/docs/source-maps#using-source-maps-on-many-domains
   */
  transform: (payload: any) => {
    const trace = payload.body.trace
    // This is a better regex for our domains than what Rollbar documents
    const locRegex = /(https?):\/\/(?:[a-z0-9._-]+\.)?[a-z-]+\.(?:com|net)(.*)/
    if (trace?.frames) {
      for (let i = 0; i < trace.frames.length; i++) {
        const filename: string = trace.frames[i].filename
        if (filename) {
          const m = filename.match(locRegex)
          if (m) {
            // Be sure that the minified_url when uploading includes 'dynamichost'
            trace.frames[i].filename = m[1] + '://dynamichost' + m[2]
          }
        }
      }
    }
  }
})
