import { injectable, inject } from 'inversify'
import { HttpMethods } from '@servicestack/client'
import IDENTIFIERS from '../../shared/inversify.types'
import IApiClientFactory from '../../shared/IApiClientFactory'
import type IAppleServicesUserService from './IAppleServicesUserService'
import type PostAppleServicesUserRequest from '../../dtos/v1/Partnerize/PostAppleServicesUserRequest'
import type PostAppleServicesUserResponse from '../../dtos/v1/Partnerize/PostAppleServicesUserResponse'
import type GetAppleServicesUserResponse from '../../dtos/v1/Partnerize/GetAppleServicesUserResponse'
import env from '/env.js'

@injectable()
export default class AppleServicesUserService implements IAppleServicesUserService {
  private readonly _apiClientFactory: IApiClientFactory
  private readonly _baseUrl: string

  constructor(
  @inject(IDENTIFIERS.ApiClientFactory) apiClientFactory: IApiClientFactory) {
    this._apiClientFactory = apiClientFactory
    this._baseUrl = `${env.BL_API_HOSTNAME}/v1/pz-users?format=json`
  }

  public async get(): Promise<GetAppleServicesUserResponse> {
    const apiClient = this._apiClientFactory.getApiClient()
    return await apiClient.send(HttpMethods.Get, {}, null, this._baseUrl)
  }

  public async post(request: PostAppleServicesUserRequest): Promise<PostAppleServicesUserResponse> {
    const apiClient = this._apiClientFactory.getApiClient()
    return await apiClient.send(HttpMethods.Post, request, null, this._baseUrl)
  }
}
