import { injectable } from 'inversify'
import AuthSession from '../models/AuthSession'
import type { IApiClient } from './IApiClient'
import flatry from 'flatry'

/**
 * Operations on sessionStorage to store and check for auth state
 * IMPORTANT THINGS TO NOTE:
 *  - sessionStorage is to keep track of auth state for DISPLAY purposes (no sensitive auth info)
 *  - sessionStorage is ONLY cleared when the page session ends (when tab or browser is closed)
 *  - sessionStorage is not shared between tabs (each tab maintains their own session)
 *  - thus, sessionStorage is NOT cleared nor updated on page refresh
 *  - if we need data to be shared across multiple tabs, use localStorage
 *
 * @note 2023-04-11
 * @todo This can probably be refactored a bit to not have to run JSON.parse and sessionStorage
 *       read for every retrieval of values. That is, ideally this would be a local state object.
 */
@injectable()
export default class AuthSessionStorage {
  private readonly Key: string

  constructor() {
    /**
     * Use a different key for each environment so no session conflicts, e.g.:
     *      - [Prod] booklinker::booklinker.com::whoami
     *      - [Stage] booklinker::stage.booklinker.com::whoami
     *
     * @note - This isn't necessary as sessionStorage (like localStorage) is not global,
     *         but is unique per origin (protocal & domain & port).
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
     */
    this.Key = `booklinker::${window?.location?.hostname || 'localhost'}::whoami`
  }

  /**
   * Since each tab maintains their own session (and has their own sessionStorage)
   * there might be edge cases where, e.g.:
   *
   *      We have 2 tabs open and we click on a new login link (for another account) on one of these tabs
   *      As a result, one tab will show user is logged in with the old account, and
   *      the other tab will show that user is logged in with the new account
   *      (Note that this is only DISPLAY issue - auth tokens are always updated across tabs)
   *
   * ..which is why we utilize localStorage to check email address of the most recent user to login
   *
   * @note 2023-06-02
   * @todo This could be simplified with useLocalStorage from vueuse,
   *       which syncs across tabs and is reactive.
   * @see https://vueuse.org/core/useLocalStorage/
   *
   * @param authSession retrieved from the tab's own sessionStorage
   * @returns updated authSession
   */
  private refresh(authSession: AuthSession): AuthSession {
    const email = localStorage.getItem(this.Key)?.trim() ?? ''
    if (authSession.isAuthenticated && email !== '' && email !== authSession.email) {
      authSession.email = email
      this.set(authSession)
    }
    return authSession
  }

  // Gets auth state from sessionStorage
  public get(): AuthSession {
    const def = new AuthSession()
    try {
      const authSession: AuthSession = JSON.parse(sessionStorage.getItem(this.Key) ?? JSON.stringify(def))
      if (authSession.userId) {
        return this.refresh(authSession)
      }
      return def
    } catch {
      return def
    }
  }

  /**
   * Sets auth state to sessionStorage and email address to localStorage.
   *
   * Ideally, we wouldn't set unless the AuthSession is fully valid,
   * but we can do this via type checks
   */
  public set(authSession: AuthSession): void {
    localStorage.setItem(this.Key, authSession?.email || '')
    sessionStorage.setItem(this.Key, JSON.stringify(authSession))
  }

  // Calls /auth API endpoint to check if user is authenticated (only if user hasn't been authenticated)
  public async checkAuthEndpoint(apiClient: IApiClient): Promise<void> {
    if (!this.get().isAuthenticated) {
      const [err, res] = await flatry(apiClient.auth())
      if (err) {
        this.set(new AuthSession())
        return
      }
      const sessionId = res.sessionId
      const hasSessionId = sessionId != null && sessionId.trim() !== ''
      let hasErrors = false
      if (res.responseStatus != null) {
        const responseStatus = res.responseStatus
        hasErrors = responseStatus.errorCode != null && responseStatus.errorCode.trim() !== ''
      }
      const isAuthenticated = hasSessionId && !hasErrors
      let authSession: AuthSession
      if (isAuthenticated) {
        authSession = new AuthSession(isAuthenticated, res.userName, res.userId)
      } else {
        authSession = new AuthSession()
      }
      this.set(authSession)
    }
  }

  // Set email of user to impersonate - role and user email validation is done in the API
  public impersonate(email: string): void {
    const currentSess = this.get()
    currentSess.impersonateAs = email
    this.set(currentSess)
  }
}
