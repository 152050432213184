import { injectable, inject } from 'inversify'
import { HttpMethods } from '@servicestack/client'
import IDENTIFIERS from '../../shared/inversify.types'
import IApiClientFactory from '../../shared/IApiClientFactory'
import type IAppleServicesTermsService from './IAppleServicesTermsService'
import type GetAppleServicesTermsResponse from '../../dtos/v1/Partnerize/GetAppleServicesTermsResponse'
import env from '/env.js'

@injectable()
export default class AppleServicesTermsService implements IAppleServicesTermsService {
  private readonly _apiClientFactory: IApiClientFactory
  private readonly _baseUrl: string

  constructor(
  @inject(IDENTIFIERS.ApiClientFactory) apiClientFactory: IApiClientFactory) {
    this._apiClientFactory = apiClientFactory
    this._baseUrl = `${env.BL_API_HOSTNAME}/v1/pz-terms?format=json`
  }

  public async get(): Promise<GetAppleServicesTermsResponse> {
    const apiClient = this._apiClientFactory.getApiClient()
    return await apiClient.send(HttpMethods.Get, {}, null, this._baseUrl)
  }
}
