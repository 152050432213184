import container from '../inversify.config'
import IDENTIFIERS from './scripts/shared/inversify.types'
import { createWebHistory, createRouter } from 'vue-router'
import type IApiClientFactory from './scripts/shared/IApiClientFactory'
import type IAuthSessionStorage from './scripts/shared/IAuthSessionStorage'
import { routes } from './Routes'
import SegmentProxy from './scripts/shared/SegmentProxy'

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  // https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to, from, savedPosition) {
    // allows us to use url fragments, e.g. /faq#ftc - this won't work otherwise
    if (to.hash) {
      return new Promise((resolve, reject) => {
        // If we're going from section to section on the same page, no scroll transition delay
        const timeoutInMs = from.path !== to.path ? 300 : 0
        setTimeout(() => {
          resolve({
            behavior: 'smooth',
            el: to.hash,
            left: 0,
            top: 20 // offset section position a little
          })
        }, timeoutInMs)
      })
    }
    // always start at top of page by default
    return { left: 0, top: 0 }
  }
})

router.beforeEach(async (to, from) => {
  const authSessionStorage = container.get<IAuthSessionStorage>(IDENTIFIERS.AuthSessionStorage)
  const apiClientFactory = container.get<IApiClientFactory>(IDENTIFIERS.ApiClientFactory)

  const apiClient = apiClientFactory.getApiClient()

  /**
   * Do a hard link if this is actually an external application (like the blog)
   */
  if (to.meta.external && !location.href.includes(to.fullPath)) {
    location.href = to.fullPath
    return true
  }

  // Check /auth endpoint directly for admin-only pages
  if (to.meta.adminOnly) {
    try {
      const res = await apiClient.auth()
      if (!res.roles?.includes('Administrator')) {
        // If auth'd, but not admin trying to access an admin page, kick to home page
        return '/'
      }
    } catch {
      // If not auth'd, kick to home page
      return '/'
    }
    // Otherwise, go through the regular flow of checking for auth
  }

  await authSessionStorage.checkAuthEndpoint(apiClient)
  const authSession = authSessionStorage.get()
  const isAuthenticated = authSession.isAuthenticated
  const requiresAuth = to.meta.requiresAuth && !isAuthenticated
  const alreadyAuthed = isAuthenticated && to.name === 'LoginForm'
  if (requiresAuth) {
    // If this page requires auth (but user not auth'd), kick user to the login page
    return '/login'
  } else if (alreadyAuthed) {
    // If already-logged-in user is on /login, redirect them to the home page
    return '/'
  // Otherwise, this page doesn't require auth and lets user stay on the page
  } else { return true }
})
router.afterEach(to => {
  SegmentProxy.page(to.fullPath)
})

export default router
