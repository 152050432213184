import Cache from 'swrv/dist/cache'

let cache = new Cache()

export default {
  get() {
    return cache
  },
  reset() {
    cache = new Cache()
  }
}
