import { injectable } from 'inversify'
import BaseService from './BaseService'
import type GetAccountByAdminResponse from '../dtos/v1/GetAccountByAdminResponse'
import { Paths } from '../constants/Paths'

@injectable()
export default class AdminService extends BaseService {
  async get(userEmail: string) {
    return await this.apiClient.get<GetAccountByAdminResponse>(
      this.url(
        Paths.Admin, { userEmail }
      )
    )
  }
}
