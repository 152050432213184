import { injectable } from 'inversify'
import BaseService from './BaseService'
import type AffiliateTokensResponse from '../dtos/v1/AffiliateTokensRespose'
import type GetAffiliateTokensResponse from '../dtos/v1/GetAffiliateTokensResponse'
import type { GetAppleServicesResponse, GetTermsResponse } from '../dtos/v2/Affiliate'
import { Paths } from '../constants/Paths'

export type UpdateAppleParams = Partial<Pick<GetAppleServicesResponse, 'selectedOption' | 'customToken'>>

@injectable()
export default class AffiliateSettingsService extends BaseService {
  getTerms() {
    return this.apiClient.get<GetTermsResponse>(Paths.PartnerizeTerms)
  }

  enrollUser(parameters: { email: string, username: string, termsAndConditionsId: string }) {
    return this.apiClient.post<GetAppleServicesResponse>(
      Paths.PartnerizeUsers, parameters
    )
  }

  getApple() {
    return this.apiClient.use<GetAppleServicesResponse>(Paths.AppleServices)
  }

  updateApple(parameters: UpdateAppleParams) {
    return this.apiClient.put<AffiliateTokensResponse>(
      this.url(Paths.AppleServices), parameters
    )
  }

  async get() {
    return await this.apiClient.get<GetAffiliateTokensResponse>(
      Paths.AffiliateTokens
    )
  }

  async post(programGuid: string, parameters: Record<string, string>) {
    return await this.apiClient.post<AffiliateTokensResponse>(
      this.url(Paths.AffiliateTokens, { programGuid }), { parameters }
    )
  }

  async put(guid: string, parameters: Record<string, string>) {
    return await this.apiClient.put<AffiliateTokensResponse>(
      this.url(Paths.AffiliateTokens, { guid }), { parameters }
    )
  }

  async delete(guid: string) {
    return await this.apiClient.delete<AffiliateTokensResponse>(
      this.url(Paths.AffiliateTokens, { guid })
    )
  }
}
