export const enum Paths {
  /** GET, PUT */
  Account = '/v2/account',
  /** GET */
  Admin = '/v1/admin/account',
  /** GET, POST, PUT, DELETE */
  AffiliateTokens = '/v1/affiliate-tokens',
  /** POST */
  AuthLogin = '/v1/login',

  AuthSignup = '/v1/signup',
  AuthSignupLink = '/v1/signup/link',
  GeniusLinkUpgrade = '/v1/geniuslink-upgrade',
  Image = '/v2/images',
  ImageById = '/v2/images/:id',
  LinkIssueReport = '/v1/report-link-issue',

  /** I would maybe swap metadata to the first path for v2 */
  LinkShortUrlMetadata = '/v1/shortlink/metadata',
  LinkProductMetadata = '/v1/product/metadata',
  LinkIsbnMetadata = '/v1/product/metadata/isbn',

  LinkPending = '/v1/links/pending',
  LinkVanityCodes = '/v1/vanity-codes/availability',
  Link = '/v1/links',
  LinkUnclaimed = '/v1/links/unclaimed', // ??
  LinkList = '/v1/links/list',
  LinkCount = '/v1/links/count',
  /** GET */
  LinkReport = '/v1/links/report',

  Collections = '/v2/collections',
  CollectionsList = '/v2/collections/list',
  CollectionById = '/v2/collections/:id',

  UserSubscription = '/v1/users/subscribe',

  // affiliate settings
  AppleServices = '/v2/settings/apple-services',
  PartnerizeTerms = '/v2/pz-terms',
  PartnerizeUsers = '/v2/pz-users'
}
