import { injectable } from 'inversify'
import ApiClient from './ApiClient'
import type { IApiClient } from './IApiClient'
import type IApiClientFactory from './IApiClientFactory'

@injectable()
export default class ApiClientFactory implements IApiClientFactory {
  getApiClient(): IApiClient {
    return new ApiClient()
  }
}
