export default class AuthSession {
  public email: string
  public userId: string
  public isAuthenticated: boolean
  public impersonateAs: string

  /**
   * If `isAuthenticated` is true, then `email` and `userId` are required
   *
   * Note that code can still flip `isAuthenticated` to true without
   * setting email / userId, but probably no reason to prevent that
   * as long as we never do that (except in tests).
   */
  constructor(isAuthenticated: true, email: string, userId: string)
  constructor(isAuthenticated?: false, email?: string, userId?: string)
  constructor(isAuthenticated?: boolean, email?: string, userId?: string) {
    this.isAuthenticated = isAuthenticated ?? false
    this.email = email ?? ''
    this.userId = userId ?? ''
  }
}
