/* eslint-disable no-extend-native */
const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E']

export { }
declare global {
  export interface Number {
    abbreviate(): string
  }
}

Number.prototype.abbreviate = function(this: number): string {
  try {
    // determine SI symbol, otherwise return the number as is
    const symbol = Math.log10(Math.abs(this)) / 3 | 0
    if (symbol === 0) return this.toString()

    // if there's a suffix/symbol, append it
    const suffix = SI_SYMBOL[symbol]
    const scale = Math.pow(10, symbol * 3)
    const scaled = this / scale
    return scaled.toFixed(1) + suffix
  } catch {
    return this.toString()
  }
}
