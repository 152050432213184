import { injectable } from 'inversify'
import type PostLinkResponse from '../dtos/v1/PostLinkResponse'
import type PostPendingLinkResponse from '../dtos/v1/PostPendingLinkResponse'
import type GetVanityCodeAvailabilityResponse from '../dtos/v1/GetVanityCodeAvailabilityResponse'
import type { GetLinksResponse } from '../dtos/v1/GetLinksResponse'
import type GetLinksCountResponse from '../dtos/v1/GetLinksResponseCount'
import PutLinkUpdateRequest from '../dtos/v1/PutLinkUpdateRequest'
import type PutLinkUpdateResponse from '../dtos/v1/PutLinkUpdateResponse'
import PostLinkCreationRequest from '../dtos/v1/PostLinkCreationRequest'
import BaseService from './BaseService'
import type { GetCollectionsResponse, GetCollectionResponse } from '../dtos/v2/Collections'
import type { NewCollectionResource, UpdatedCollectionResource } from '../dtos/v2/Resources'
import { type RefTypes } from './utils'
import { Paths } from '../constants/Paths'
import flatry from 'flatry'

@injectable()
export default class LinkService extends BaseService {
  async createPendingLink(token: string): Promise<PostPendingLinkResponse> {
    return await this.apiClient.post<PostPendingLinkResponse>(
      Paths.LinkPending, { token }
    )
  }

  private isValidVanityCode(code: string): boolean {
    code = code.replaceAll(' ', '') // don't allow whitespaces
    if (code === '' || /^[a-zA-Z0-9-_]{3,22}$/.test(code)) {
      return true
    }
    return false
  }

  async getVanityCodeAvailability(domain: string, code: string): Promise<GetVanityCodeAvailabilityResponse> {
    if (!this.isValidVanityCode(code)) {
      throw new Error('Only hyphens, underscores, and alphanumerics 3-22 characters in length allowed')
    }

    code = code.replaceAll(' ', '')
    if (code === '') {
      return { isAvailable: true }
    }

    const [err, res] = await flatry(
      this.apiClient.get<GetVanityCodeAvailabilityResponse>(
        this.url(Paths.LinkVanityCodes, { domain, code })
      )
    )
    if (err) {
      return await Promise.reject(err)
    }
    if (!res.isAvailable) {
      throw new Error('Sorry, that url is already taken. Please try another one.')
    }
    return res
  }

  async createLink(isUnclaimed: boolean, url: string, code: string,
    parameterKeyValues: Record<string, string>, affiliateParams: Record<string, any>): Promise<PostLinkResponse> {
    const payload = new PostLinkCreationRequest(url, code, parameterKeyValues, affiliateParams)
    return await this.apiClient.post(
      isUnclaimed ? Paths.LinkUnclaimed : Paths.Link, payload
    )
  }

  getLinks({ skip = 0, take = 20, ids }: RefTypes<{ skip?: number, take?: number, ids?: string[] }>) {
    return this.apiClient.use<GetLinksResponse, 'results'>(
      () => this.url(Paths.LinkList, { skip, take, ids }, true),
      'results'
    )
  }

  getCollections({ offset = 0, limit = 20, ids }: RefTypes<{ offset?: number, limit?: number, ids?: string[] }>) {
    return this.apiClient.use<GetCollectionsResponse>(
      () => this.url(Paths.CollectionsList, { offset, limit, ids }, true)
    )
  }

  getCollection({ id }: RefTypes<{ id: string }>) {
    return this.apiClient.use<GetCollectionResponse>(
      this.url(Paths.CollectionById, { id })
    )
  }

  async updateCollection({ id, title, linkIds }: RefTypes<UpdatedCollectionResource>) {
    return await this.apiClient.put(
      this.url(Paths.CollectionById, { id }), { title, linkIds }
    )
  }

  async createCollection({ title, linkIds, aliasCode }: NewCollectionResource) {
    const [err] = await flatry(
      this.getVanityCodeAvailability('', aliasCode)
    )
    if (err) {
      return await Promise.reject(err)
    }
    await this.apiClient.post(
      Paths.Collections, { title, linkIds, aliasCode }
    )
  }

  async deleteCollection({ id }: RefTypes<{ id: string }>) {
    return await this.apiClient.delete(
      this.url(Paths.CollectionById, { id })
    )
  }

  getLinksCount() {
    return this.apiClient.use<GetLinksCountResponse>(
      Paths.LinkCount
    )
  }

  async updateLink(domain: string, code: string, baseCode: string, url: string): Promise<PutLinkUpdateResponse> {
    const payload = new PutLinkUpdateRequest(domain, code, baseCode, url)
    return await this.apiClient.put<PutLinkUpdateResponse>(
      Paths.Link, payload
    )
  }
}
